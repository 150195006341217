import axiosInstance from "axiosInstance";

const localStorageKey = "authToken";

function getToken() {
  return window.localStorage.getItem(localStorageKey);
}

function handleUserResponse({ data, headers }) {
  window.localStorage.setItem("sessions", headers["x-session"]);
  window.localStorage.setItem(
    localStorageKey,
    headers["x-amzn-remapped-authorization"] || headers["authorization"]
  );
  return {
    data,
    token: headers["x-amzn-remapped-authorization"] || headers["authorization"],
  };
}

function login({ email, password }) {
  return client("auth/login", { email, password }).then(handleUserResponse);
}

function register({ username, password, email }) {
  return client("auth/register", { username, password, email }).then(
    handleUserResponse
  );
}

// async function logout() {
//   window.localStorage.removeItem(localStorageKey)
// }

async function getUserData() {
  let user = null;
  const token = getToken();
  if (token) {
    try {
      let data = await axiosInstance.get(`/auth/user`, {
        headers: {
          Authorization: token,
          skipAuthToken: true,
        },
      });
      user = data.data;
      return user;
    } catch (e) {
      if (e?.response?.data === "Token Expired") {
        localStorage.clear();
        return null;
      }
    }
  }
}

async function client(endpoint, data) {
  return await axiosInstance
    .post(endpoint, data, { _showMessage: true })
    .then(async (response) => {
      if (response.status === 200) {
        return response;
      } else {
        return Promise.reject(data);
      }
    });
}

export { getToken, login, register, localStorageKey, getUserData };
