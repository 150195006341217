import { Dialog, DialogActions, DialogContent, makeStyles } from '@material-ui/core';
import Button from 'components/V2/Button';
import React, { useState } from 'react';

export const GlobalModalContext = React.createContext({});

const useStyles = makeStyles({
  dialog: {
    backgroundColor: '#7cb7b5',
    color: '#414244',
    width: '304px',
    borderRadius: '0px'
  },
  content: {
    fontSize: 12,
    // letterSpacing: 0.24,
    // textAlign: "left",
    color: '#262626',
    padding: '20px 40px',
    textAlign: 'center'
  },
  actions: {
    padding: '10px 40px 30px',
    justifyContent: 'center'
  }
});

function GlobalModal(props) {
  const [open, setIsOpen] = useState(false);
  const [modalContent, setContent] = useState('');
  const [modalActions, setActions] = useState([]);
  const classes = useStyles();
  const { children } = props;
  return (
    <GlobalModalContext.Provider value={{ displayModal: displayModal }}>
      <Dialog classes={{ paper: classes.dialog }} open={open} onClose={closeModal}>
        {/* <DialogTitle>Dialog Title goes here</DialogTitle> */}
        <DialogContent className={classes.content}>{modalContent}</DialogContent>
        <DialogActions className={classes.actions}>
          {/* <Button
              width="93px"
              fontSize="11px"
              height="30px"
              className="mr-4 flex items-center justify-center"
              variant="greyishTealFilled"
            >
              Okay
            </Button>
            <Button
              width="93px"
              className="flex items-center justify-center"
              fontSize="11px"
              height="30px"
              variant="whiteOutlined"
            >
              Okay
            </Button> */}
          {modalActions.map((item, index) => {
            return (
              <Button
                key={index.toString()}
                onClick={item?.isDismiss ? closeModal : item?.onClick || (() => {})}
                width="93px"
                className="flex items-center justify-center"
                fontSize="11px"
                height="30px"
                variant={item.buttonVariant === 'filled' ? 'greyishTealFilled' : 'whiteOutlined'}
              >
                {item.buttonTitle}
              </Button>
            );
          })}
        </DialogActions>
      </Dialog>
      {children}
    </GlobalModalContext.Provider>
  );

  function closeModal() {
    setIsOpen(false);
    setContent('');
    setActions([]);
  }

  function displayModal({ content = '', actions = [] }) {
    setIsOpen(true);
    setContent(content);
    setActions(actions);
  }
}

export default GlobalModal;
