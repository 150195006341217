import React, { useState, useContext, useMemo, useEffect } from "react";
import axiosInstance from "axiosInstance";
import Axios from "axios";
import { useSnackbar } from "notistack";
import { useAsync } from "utils/hooks";
import * as auth from "auth-provider";
import Loading from "components/common/Loading";

export const AuthenticationContext = React.createContext();
AuthenticationContext.displayName = "AuthContext";

const AuthenticationProvider = (props) => {
  const [user, setUser] = useState(null);
  const [authToken, setAuthToken] = useState("");
  const {
    status,
    error,
    isLoading,
    isIdle,
    isError,
    isSuccess,
    run,
  } = useAsync();

  useEffect(() => {
    async function fetchData() {
      const userData = await auth.getUserData();
      setAuthToken(auth.getToken());
      setUser(userData);
    }
    const userDataPromise = fetchData();
    run(userDataPromise);
  }, []);

  useEffect(() => {
    if (authToken) {
      const reqInterceptorId = axiosInstance.interceptors.request.use(
        function (config) {
          if (config.headers.skipAuthToken) {
            const { skipAuthToken, ...rest } = config.headers;
            config.headers = rest;
            return config;
          }
          config.headers = { ...config.headers, Authorization: authToken };
          return config;
        },
        function (error) {
          throw error;
        }
      );
      const resInterceptorId = axiosInstance.interceptors.response.use(
        function (response) {
          return response;
        },
        function (error) {
          throw error;
        }
      );
      return () => {
        Axios.interceptors.request.eject(reqInterceptorId);
        Axios.interceptors.request.eject(resInterceptorId);
      };
    }
  }, [authToken]);

  const login = React.useCallback(
    (form) =>
      auth
        .login(form)
        .then(({ data, token }) => {
          setAuthToken(token);
          return setUser(data);
        })
        .catch((e) => {}),
    [setUser, setAuthToken]
  );

  const register = React.useCallback(
    (form) =>
      auth.register(form).then(({ data, token }) => {
        setAuthToken(token);
        return setUser(data);
      }),
    [setUser, setAuthToken]
  );

  const logout = React.useCallback(() => {
    setUser(null);
    localStorage.clear();
    setAuthToken(null);
  }, [setUser]);

  const updateUser = React.useCallback(
    (data) => {
      setUser(data);
    },
    [setUser]
  );

  const value = useMemo(
    () => ({
      user,
      login,
      updateUser,
      logout,
      register,
      authToken,
      userData: user,
    }),
    [login, logout, register, user, authToken, updateUser]
  );

  if (isLoading || isIdle) {
    return <Loading />;
  }

  if (isError) {
    return;
  }

  if (isSuccess) {
    return <AuthenticationContext.Provider value={value} {...props} />;
  }
};

function useAuth() {
  const authentication = useContext(AuthenticationContext);
  return authentication;
}

export { AuthenticationProvider, useAuth };
