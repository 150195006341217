import React from "react";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  yellowOutlined: {
    fontSize: (props) => props?.fontSize || "14px",
    width: (props) => props?.width || "auto",
    height: (props) => props?.height || "auto",
    padding: "10px",
    // paddingLeft: "8px",
    // paddingRight: "8px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    lineHeight: "1.45",
    letterSpacing: "1.5px",
    textAlign: "center",

    color: (props) => props?.textColor || "#e6e6e6",
    border: (props) => `solid ${props.borderWidth || 1}px #fac90e`,
    borderColor: (props) => props?.borderColor,
    textTransform: "uppercase",
    borderRadius: "5px",
    "&:hover": {
      borderColor: "#fac90e !important",
      background: "#fac90e",
      color: "black",
    },
    "&:focus": {
      borderColor: "#fac90e !important",
      background: "#fac90e",
      // boxShadow: "0 0 5px #fac90e",
      color: "black",
    },
  },
  whiteOutlinedButton: {
    fontSize: (props) => props?.fontSize || "14px",
    width: (props) => props?.width || "auto",
    height: (props) => props?.height || "auto",
    padding: "10px",
    // paddingLeft: "8px",
    // paddingRight: "8px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    lineHeight: "1.45",
    letterSpacing: "1.5px",
    textAlign: "center",

    color: (props) => props?.textColor || "#e6e6e6",
    border: (props) => `solid ${props.borderWidth || 1}px #fac90e`,
    borderColor: (props) => props?.borderColor,
    textTransform: "uppercase",
    borderRadius: "5px",
    "&:hover": {
      borderColor: "#fac90e !important",
      background: "#fac90e",
      color: "black",
    },
    "&:focus": {
      borderColor: "#fac90e !important",
      background: "#fac90e",
      // boxShadow: "0 0 5px #fac90e",
      color: "black",
    },
  },
  secondary: {
    fontSize: (props) => props?.fontSize || "14px",
    width: (props) => props?.width || "auto",
    height: (props) => props?.height || "auto",
    background: "#f0f0e9",
    color: "#323336",
    padding: (props) => props?.padding || "10px",
    borderRadius: "5px",
    textTransform: "uppercase",
    // "&:hover": {
    //   borderColor: "#fac90e !important",
    //   background: "#fac90e",
    //   color: "black",
    // },
  },
  transparentWhite: {
    border: "solid 1px #f0f0e9",
    fontSize: (props) => props?.fontSize || "14px",
    width: (props) => props?.width || "auto",
    height: (props) => props?.height || "auto",
    padding: (props) => props?.padding || "10px",
    color: "#f0f0e9",
    borderRadius: "5px",
    textTransform: "uppercase",
    "&:hover": {
      borderColor: "#fac90e !important",
      background: "#fac90e",
      color: "black",
    },
    "&:focus": {
      borderColor: "#fac90e !important",
      background: "#fac90e",
      // boxShadow: "0 0 5px #fac90e",
      color: "black",
    },
  },
  filledBackground: {
    fontSize: (props) => props?.fontSize || "14px",
    width: (props) => props?.width || "auto",
    height: (props) => props?.height || "auto",
    backgroundColor: (props) => props?.backgroundColor || "inherit",
    padding: (props) => props?.padding || "10px",
    borderRadius: "5px",
    textTransform: "uppercase",
    color: "#f0f0e9",
  },
  yellowFilled: {
    fontSize: (props: any) => props?.fontSize || "14px",
    width: (props: any) => props?.width || "auto",
    height: (props: any) => props?.height || "auto",
    padding: (props: any) => props?.padding || "10px",
    borderRadius: "5px",
    textTransform: "uppercase",
    color: "#424447",
    backgroundColor: "#fac90e",
    fontWeight: 600,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    // "&:hover": {
    //   borderColor: "#fac90e !important",
    //   background: "#fac90e",
    //   color: "black",
    // },
    // "&:focus": {
    //   borderColor: "#fac90e !important",
    //   background: "#fac90e",
    //   // boxShadow: "0 0 5px #fac90e",
    //   color: "black",
    // },
    "&:disabled": {
      color: "#424447",
    },
  },
  whiteOutlined: {
    fontSize: (props) => props?.fontSize || "12px",
    width: (props) => props?.width || "auto",
    height: (props) => props?.height || "auto",
    padding: (props) => props?.padding || "10px",
    borderRadius: "5px",
    textTransform: "uppercase",
    backgroundColor: "none",
    color: "white",
    border: "1px solid white",
    fontWeight: 600,
  },
  greyishTealFilled: {
    fontSize: (props) => props?.fontSize || "12px",
    width: (props) => props?.width || "auto",
    height: (props) => props?.height || "auto",
    padding: (props) => props?.padding || "10px",
    borderRadius: "5px",
    textTransform: "uppercase",
    backgroundColor: "#72a09e",
    color: "white",
    letterSpacing: "1.5px",
  },
}));

// TODO:: Handle different kinds of buttons over here

function Button(props) {
  const {
    onClick,
    disabled,
    children,
    className,
    style = {},
    width,
    height,
    fontSize,
    borderColor,
    backgroundColor,
    textColor,
    borderWidth,
    variant = "whiteOutlinedButton",
    type,
  } = props;
  const classes = useStyles({
    width,
    height,
    fontSize,
    borderColor,
    backgroundColor,
    borderWidth,
    textColor,
  });
  return (
    <button
      type={type}
      className={`${
        classes[variant] || classes.whiteOutlinedButton
      } ${className}`}
      onClick={onClick}
      disabled={disabled}
      style={style}
    >
      {children}
    </button>
  );
}

export default Button;
