import * as React from "react";
import { useAuth } from "context/auth-context";
import Loading from "components/common/Loading";
import { BrowserRouter as Router } from "react-router-dom";

const AuthenticatedApp = React.lazy(() =>
  import(/* webpackPrefetch: true */ "./authenticated-app")
);
const UnauthenticatedApp = React.lazy(() => import("./unauthenticated-app"));

function App() {
  const { user } = useAuth();
  return (
    <React.Suspense fallback={<Loading />}>
      {user ? (
        <Router>
          <AuthenticatedApp />
        </Router>
      ) : (
        <Router>
          <UnauthenticatedApp />
        </Router>
      )}
    </React.Suspense>
  );
}

export { App };
